import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ISiteDataState } from "./types";

const initialState: ISiteDataState = {
  comments: null,
  data: null,
};

const siteDataState = createSlice({
  name: "siteData",
  initialState,
  reducers: {
    updateSiteDataState: (
      state,
      action: PayloadAction<Partial<ISiteDataState>>,
    ) => {
      state.comments = action.payload.comments ?? state.comments;
      state.data = action.payload.data ?? state.data;
    },
  },
});

export const { updateSiteDataState } = siteDataState.actions;
export default siteDataState.reducer;
