import { IUserData } from "@/interfaces";
import { useAppDispatch, useAppSelector } from "@/redux";
import { updateAuthState } from "@/redux/reducers/auth";
import { toggleSidebar } from "@/redux/reducers/layout";
import { Anchor, AppShell, Box, Burger, Button, Group } from "@mantine/core";
import { googleLogout, useGoogleLogin } from "@react-oauth/google";
import { IconWashDryW } from "@tabler/icons-react";
import React from "react";
import { Link } from "react-router-dom";
import { AppSpotlight } from "./AppSpotlight";
import {
  useGoogleAuthLogin,
  useGoogleLocalStorage,
  useGoogleRefreshToken,
} from "./hooks";

function AppHeader() {
  const dispatch = useAppDispatch();
  const isAuthenticated = useAppSelector((state) => state.auth.isAuthenticated);
  const isSidebarOpen = useAppSelector((state) => state.layout.isSidebarOpen);
  const [token, setToken, removeToken] = useGoogleLocalStorage();
  const { mutate: googleAuthLogin } = useGoogleAuthLogin({
    onSuccess: (data) => {
      setIsAuthenticated(true, data);
    },
  });
  const { mutate: googleCheckLogin } = useGoogleRefreshToken({
    onSuccess: (data) => {
      setIsAuthenticated(true, data);
    },
  });
  const login = useGoogleLogin({
    flow: "auth-code",
    select_account: true,
    onSuccess: (credentialResponse) => {
      googleAuthLogin({ code: credentialResponse.code });
    },
    onError: (errorResponse) => {
      console.error("Login Failed", errorResponse);
      setIsAuthenticated(false, null);
    },
  });

  const logout = () => {
    setIsAuthenticated(false, null);
    removeToken();
    googleLogout();
  };

  const setIsAuthenticated = (auth: boolean, user: IUserData | null) => {
    if (user) {
      const { idToken, ...userData } = user;
      setToken((prev) => ({
        isAuthenticated: auth,
        exp: userData.exp,
        idToken: idToken ? idToken : prev.idToken,
      }));
      dispatch(updateAuthState({ isAuthenticated: auth, user: userData }));
    } else {
      dispatch(updateAuthState({ isAuthenticated: auth, user }));
    }
  };

  React.useEffect(() => {
    if (token.isAuthenticated && token.exp > 0 && !isAuthenticated) {
      googleCheckLogin({ token: "" });
    }
  }, [token, googleCheckLogin, isAuthenticated]);

  return (
    <AppShell.Header>
      <Group h="100%" px="md" justify="space-between" gap={12}>
        <Group>
          <Burger
            opened={isSidebarOpen}
            onClick={() => {
              dispatch(toggleSidebar({ isSidebarOpen: !isSidebarOpen }));
            }}
            hiddenFrom="md"
            size="sm"
          />
          <Anchor
            component={Link}
            to={"/"}
            underline="never"
            className="tw-flex tw-items-center tw-gap-1"
          >
            <IconWashDryW size={32} />
            <Box mt={4} visibleFrom="md">
              Product Wiki
            </Box>
          </Anchor>
        </Group>
        <Group visibleFrom="sm">
          <Anchor
            component={Link}
            to={"/app/dashboard"}
            underline="never"
            className="tw-text-black hover:tw-text-[color:var(--mantine-color-anchor)]"
          >
            Dashboard
          </Anchor>
          <Anchor
            component={Link}
            to={"/"}
            underline="never"
            className="tw-text-black hover:tw-text-[color:var(--mantine-color-anchor)]"
          >
            Marketplace
          </Anchor>
          <Anchor
            component={Link}
            to={"/"}
            underline="never"
            className="tw-text-black hover:tw-text-[color:var(--mantine-color-anchor)]"
          >
            Advertise
          </Anchor>
          <Anchor
            component={Link}
            to={"/"}
            underline="never"
            className="tw-text-black hover:tw-text-[color:var(--mantine-color-anchor)]"
          >
            About
          </Anchor>
        </Group>
        <Group>
          <AppSpotlight />
          {isAuthenticated ? (
            <Button fw={500} fz={16} variant="outline" onClick={() => logout()}>
              Logout
            </Button>
          ) : (
            <>
              <Button
                fw={400}
                fz={16}
                variant="transparent"
                onClick={() => login()}
              >
                Log In
              </Button>
              <Button fw={400} fz={16} onClick={() => login()}>
                Register
              </Button>
            </>
          )}
        </Group>
      </Group>
    </AppShell.Header>
  );
}

export default AppHeader;
