import AppHeader from "@/components/AppHeader";
import { useAppSelector } from "@/redux";
import { HEADER_HEIGHT } from "@/utils/contants";
import { AppShell } from "@mantine/core";
import { Navigate } from "react-router-dom";
import AuthMain from "./AuthMain";

function AuthLayout() {
  const isAuthenticated = useAppSelector((state) => state.auth.isAuthenticated);

  if (!isAuthenticated) {
    return <Navigate to={"/"} />;
  }

  return (
    <AppShell padding={0} header={{ height: HEADER_HEIGHT }}>
      <AppHeader />
      <AuthMain />
    </AppShell>
  );
}

export default AuthLayout;
