import { NAVBAR_WIDTH } from "@/utils/contants";
import { AppShell, NavLink, ScrollArea, Stack } from "@mantine/core";
import { spotlight } from "@mantine/spotlight";
import {
  Icon,
  IconBookmarks,
  IconGiftFilled,
  IconHome,
  IconRss,
  IconSearch,
  IconSettingsAutomation,
  IconStarFilled,
  IconTimeline,
} from "@tabler/icons-react";
import { NavLink as RouterNavLink, useLocation } from "react-router-dom";

interface CommonProps {
  id: string;
  label: string;
  className?: string;
  icon: Icon;
  iconClassName?: string;
}

interface ButtonProps extends CommonProps {
  component: "button";
}

interface LinkProps extends CommonProps {
  component: "a";
  link: string;
}

type NavbarItem = ButtonProps | LinkProps;

const AppNavbar = () => {
  const location = useLocation();

  const handleOnClick: Record<string, () => void> = {
    search: spotlight.toggle,
  };

  return (
    <AppShell.Navbar w={NAVBAR_WIDTH} className="tw-justify-center">
      <ScrollArea>
        <Stack h={"100%"} justify="center" gap={4} px={8} py={"sm"}>
          {navbarItems.map((item) => {
            if (item.component === "button") {
              return (
                <NavLink
                  key={item.id}
                  component={item.component}
                  label={item.label}
                  leftSection={
                    <item.icon
                      size={20}
                      stroke={1.5}
                      className={item.iconClassName}
                    />
                  }
                  onClick={handleOnClick[item.id]}
                />
              );
            }

            return (
              <NavLink
                key={item.id}
                component={RouterNavLink}
                to={item.link}
                label={item.label}
                leftSection={
                  <item.icon
                    size={20}
                    stroke={1.5}
                    className={item.iconClassName}
                  />
                }
                active={location.pathname === item.link}
              />
            );
          })}
        </Stack>
      </ScrollArea>
    </AppShell.Navbar>
  );
};

export default AppNavbar;

const navbarItems: NavbarItem[] = [
  {
    id: "search",
    component: "button",
    label: "Search",
    className: "tw-rounded",
    icon: IconSearch,
  },
  {
    id: "free-signup",
    component: "button",
    label: "Free Signup",
    icon: IconGiftFilled,
    iconClassName: "tw-text-green-500",
  },
  {
    id: "today",
    component: "a",
    link: "/",
    label: "Today",
    icon: IconHome,
  },
  {
    id: "most-saved",
    component: "a",
    link: "/most-saved",
    label: "Most Saved",
    icon: IconBookmarks,
  },
  {
    id: "tasks",
    component: "a",
    link: "/tasks",
    label: "Tasks",
    icon: IconSettingsAutomation,
  },
  {
    id: "timeline",
    component: "a",
    link: "/timeline",
    label: "Timeline",
    icon: IconTimeline,
  },
  {
    id: "get-featured",
    component: "a",
    link: "/get-featured",
    label: "Get featured",
    icon: IconStarFilled,
    iconClassName: "tw-text-orange-500",
  },
  {
    id: "follow-us",
    component: "a",
    link: "#follow-us",
    label: "Follow Us",
    icon: IconRss,
  },
];
