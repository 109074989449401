import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IAuthState } from "./types";

const initialState: IAuthState = {
  isAuthenticated: false,
  user: null,
};

const authState = createSlice({
  name: "authentication",
  initialState,
  reducers: {
    updateAuthState: (state, action: PayloadAction<Partial<IAuthState>>) => {
      state.isAuthenticated =
        action.payload.isAuthenticated ?? state.isAuthenticated;
      state.user = action.payload.user ?? state.user;
    },
  },
});

export const { updateAuthState } = authState.actions;
export default authState.reducer;
