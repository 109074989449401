import { lazy } from "react";

const HomePage = lazy(() => import("@/pages/Home"));
const DashboardPage = lazy(() => import("@/pages/Dashboard"));
const ProductPage = lazy(() => import("@/pages/Product"));

export default {
  HomePage,
  DashboardPage,
  ProductPage,
};
