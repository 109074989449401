import AuthLayout from "@/layouts/AuthLayout";
import MainLayout from "@/layouts/MainLayout";
import { Navigate, Outlet, createBrowserRouter } from "react-router-dom";
import LazyImports from "./lazy-imports";

export const router = createBrowserRouter([
  {
    path: "",
    element: <Outlet />,
    children: [
      {
        path: "",
        element: <MainLayout />,
        children: [
          {
            path: "",
            element: <LazyImports.HomePage />,
          },
          {
            path: "product/:id",
            element: <LazyImports.ProductPage />,
          },
        ],
      },
      {
        path: "app",
        element: <AuthLayout />,
        children: [
          {
            path: "dashboard",
            element: <LazyImports.DashboardPage />,
          },
        ],
      },
    ],
  },
  {
    path: "*",
    element: <Navigate to={"/"} />,
  },
]);
