import { router } from "@/router";
import { CLIENT_ID } from "@/utils/config";
import { MantineProvider } from "@mantine/core";
import { ModalsProvider } from "@mantine/modals";
import { Notifications } from "@mantine/notifications";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { QueryClientProvider } from "@tanstack/react-query";
import { Provider } from "react-redux";
import { RouterProvider } from "react-router-dom";
import { store } from "./redux";
import { queryClient } from "./utils/contants";

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <MantineProvider
        theme={{
          primaryColor: "violet",
          fontFamily: "Geist, sans",
        }}
      >
        <Notifications position="top-right" />
        <ModalsProvider>
          <GoogleOAuthProvider clientId={CLIENT_ID}>
            <Provider store={store}>
              <RouterProvider router={router} />
            </Provider>
          </GoogleOAuthProvider>
        </ModalsProvider>
      </MantineProvider>
    </QueryClientProvider>
  );
}

export default App;
