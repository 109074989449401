import { axiosClient, getToken } from "@/helpers/axios-client";
import { ICommonResponse, IUserData } from "@/interfaces";
import { AUTH_TOKEN, BE_API_URL } from "@/utils/contants";
import { useLocalStorage } from "@mantine/hooks";
import { MutateOptions, useMutation } from "@tanstack/react-query";

export const useGoogleLocalStorage = () => {
  return useLocalStorage({
    key: AUTH_TOKEN,
    defaultValue: { isAuthenticated: false, exp: 0, idToken: "" },
    getInitialValueInEffect: true,
  });
};

export const useGoogleAuthLogin = (
  opts: MutateOptions<IUserData, Error, { code: string }>,
) => {
  return useMutation<IUserData, Error, { code: string }>({
    mutationKey: ["googleAuthLogin"],
    mutationFn: async ({ code }) => {
      const { data } = await axiosClient.post<ICommonResponse<IUserData>>(
        `${BE_API_URL}/api/user/oauth/google-login`,
        { code },
      );
      return data.data;
    },
    ...opts,
  });
};

export const useGoogleRefreshToken = (
  opts: MutateOptions<IUserData, Error, { token: string }>,
) => {
  return useMutation<IUserData, Error, { token: string }>({
    mutationKey: ["refresh-token"],
    mutationFn: async ({ token }) => {
      const { data } = await axiosClient.post<ICommonResponse<IUserData>>(
        `${BE_API_URL}/api/user/oauth/google-refresh-token`,
        { token },
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        },
      );
      return data.data;
    },
    ...opts,
  });
};
