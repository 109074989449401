import AppHeader from "@/components/AppHeader";
import AppNavbar from "@/components/AppNavbar";
import { useAppSelector } from "@/redux";
import { HEADER_HEIGHT, NAVBAR_WIDTH } from "@/utils/contants";
import { AppShell } from "@mantine/core";
import AppMain from "./AppMain";

function MainLayout() {
  const isSidebarOpen = useAppSelector((state) => state.layout.isSidebarOpen);

  return (
    <AppShell
      padding={0}
      header={{ height: HEADER_HEIGHT }}
      navbar={{
        width: NAVBAR_WIDTH,
        breakpoint: "md",
        collapsed: { mobile: !isSidebarOpen },
      }}
    >
      <AppHeader />
      <AppNavbar />
      <AppMain />
    </AppShell>
  );
}

export default MainLayout;
