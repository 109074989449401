import { AUTH_TOKEN, BE_API_URL, GOOGLE_API_URL } from "@/utils/contants";
import axios from "axios";

export const getToken = () => {
  const token = localStorage.getItem(AUTH_TOKEN) as string;
  return JSON.parse(token).idToken as string;
};

export const axiosClient = axios.create({
  baseURL: BE_API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

export const googleAxiosClient = axios.create({
  baseURL: GOOGLE_API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});
