import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ILayoutState } from "./types";

const initialState: ILayoutState = {
  isSidebarOpen: false,
};

const layoutState = createSlice({
  name: "layout",
  initialState,
  reducers: {
    toggleSidebar: (state, action: PayloadAction<ILayoutState>) => {
      state.isSidebarOpen = action.payload.isSidebarOpen ?? state.isSidebarOpen;
    },
  },
});

export const { toggleSidebar } = layoutState.actions;
export default layoutState.reducer;
