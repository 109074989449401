import { QueryClient } from "@tanstack/react-query";
import { StringifyOptions } from "query-string";

export const HEADER_HEIGHT = 80;

export const NAVBAR_WIDTH = 260;

export const AWS_S3_URL = "https://d33flfvrp0lsn1.cloudfront.net";

export const BE_API_URL = import.meta.env.DEV
  ? "http://localhost:3001"
  : "https://moder8.in";

export const GOOGLE_API_URL = "https://www.googleapis.com";

export const AUTH_TOKEN = "id";

export const DefaultQueryStringOptions: StringifyOptions = {
  arrayFormat: "comma",
  skipEmptyString: true,
  skipNull: true,
};

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
      refetchOnWindowFocus: false,
    },
    mutations: {
      retry: 0,
    },
  },
});
