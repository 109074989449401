import AppSuspense from "@/components/AppSuspense";
import { AppShell, Container } from "@mantine/core";
import { Outlet } from "react-router-dom";

function AuthMain() {
  return (
    <AppShell.Main>
      <AppSuspense>
        <Container className="tw-h-full tw-max-w-full sm:tw-max-w-[95%]">
          <Outlet />
        </Container>
      </AppSuspense>
    </AppShell.Main>
  );
}

export default AuthMain;
